/** This file is autogenerated using the dev environment */
    
import type { DefaultsEntry } from './config.types';
    
export { config } from './dev.config';
export const defaults: DefaultsEntry = {
  confirmations: {
    donation: 2,
    wirePayout: 2,
    walletPayout: 2,
    portfolioTrade: 2,
    transfer: 2,
    setManager: 2,
    orgDeployment: 2,
    fundDeployment: 2,
  },
  fees: {
    donationBps: 50,
    transferBps: 100,
    stockDonationFeeBps: 50,
    tgbEntityStockDonationFeeBps: 50,
  },
  network: {
    defaultChainId: 11155111,
    supportedNetworkSettings: [
      {
        chainId: 84532,
        baseToken: {
          id: 99,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 84532,
          contractAddress: '0x036cbd53842c5426634e7929541ec2318f3dcf7e',
        },
        nativeToken: {
          id: 98,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 84532,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0xa6a5630e3f12d691bfc9c6d5a1b122c21e12b9dd',
          orgFundFactory: '0x7f812ddaeaedf3266b2ea70c0386998a884be506',
          batchOrgDeployer: '0x0000000000000000000000000000000000000000',
          transferDeployer: '0xd7c12ff61b43b02450d536f58efccfc52c98088a',
          entityMulticall: '0xb4b067184ff22e42d16b6a59384329230eca06fd',
        },
      },
      {
        chainId: 11155111,
        baseToken: {
          id: 93,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 11155111,
          contractAddress: '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238',
        },
        nativeToken: {
          id: 90,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 11155111,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0xf5ca418c59a9afe3be683bbe5d756ce4e54ed65d',
          orgFundFactory: '0xf93a0e957c4e33a99240a18f651e6adebbdf997b',
          batchOrgDeployer: '0x2bf99a48eb5c563a323699d8c5a52de146973194',
          transferDeployer: '0x0905ea282bcc6fa477bef258de25170343f23e2e',
          entityMulticall: '0xa6956ef5041b6cbc6793fd58b5fd6b06ea5737c8',
        },
      },
      {
        chainId: 11155420,
        baseToken: {
          id: 104,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 11155420,
          contractAddress: '0x5fd84259d66cd46123540766be93dfe6d43130d7',
        },
        nativeToken: {
          id: 103,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 11155420,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0x29730cdc54d94b9305b5491c63af92e30ab53c91',
          orgFundFactory: '0xa6082eafafcd08d14736ffbb3ddb5a0c59736bd1',
          batchOrgDeployer: '0x0000000000000000000000000000000000000000',
          transferDeployer: '0x743ea21c901ef30fcdc5d8c3e3649bd1e67fabcd',
          entityMulticall: '0x28faab0430354f62cb814829006b706e1cca680e',
        },
      },
    ],
  },
  roles: {
    keeper: [
      '0x88100996d867585258f88b01cfe44cb60119eef0',
      '0xbf8e57a8195eed88f7663acf2af0b7eacdbd69bc',
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x0cb1d93daec77df2ed7db31c040fd2174452bd9f',
      '0xbb77d2c8c6fec55285c782356333afdcd4a926d8',
    ],
    accountant: [
      '0xc6f1beb66fa3d40b818f969d219958947cd4b448',
      '0x77dfb7afae21622274751358d01ad5f1d59d202a',
      '0x5398785700a9248cfc2101f0188c9788e8619974',
      '0x9928fa2b51f7e2cce76cf0a6aed1b7e4afe0cc0f',
      '0x9d5025b327e6b863e5050141c987d988c07fd8b2',
    ],
    maintainer: [
      '0xc6f1beb66fa3d40b818f969d219958947cd4b448',
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x5398785700a9248cfc2101f0188c9788e8619974',
      '0x77dfb7afae21622274751358d01ad5f1d59d202a',
      '0xc8b810fe39952aa65ca5a9387a3546b9b6bf5780',
      '0x61752014a3e9d04c3ea5de61fed4667ca43676d5',
    ],
    reviewer: ['0x78062b69cffd97e6e0bba4cd71b0ec436b634e10'],
  },
  stripePublishableKey:
    'pk_test_51NvSLXKXyuJ3LOFN0l7ehahFm1vFfaWchEEhHVqSxwDrv9abIx9iYwLdttp7EKOqEScm61DQAsLJmW1jN5ybcsfH008qiCg7im',
};
